import React, {  useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button,  Flex, theme} from '../../../../ui';
import CheckboxGroup from '../../../../ui/CheckboxGroup';
import { getDarkerThemeColor } from '../../../../ui/theme/colors';
import styled from 'styled-components';

const FlexSyled = styled(Flex)`
  .MuiFormGroup-root{
    gap:14px;
  }
`

const MultipleChoiceType = ({ results, data, onSubmit, themeColor }) => {
  const { t } = useTranslation();

  const [answers, setAnswers] = useState([]);
  const defaultValues = results.length > 0 ? 
    results.find(result => result.taskId === data.taskId)?.value : [];

  useEffect(() => {
    if (defaultValues?.length > 0) {
      setAnswers(defaultValues);
    }
  }, [defaultValues]);

  const handleSubmit = ()=>{
    answers.length && onSubmit(answers)
  }

  return (
    <FlexSyled width='100%' gap='10' direction='column'>
      <CheckboxGroup items={data.caption?.items} localSetter={setAnswers} selectedCheckboxes={answers} themeColor={getDarkerThemeColor(themeColor)}/>
      <Flex justify='flex-end' alignSelf='flex-end'>
        <Button $variant='customized' $bgcolor={getDarkerThemeColor(themeColor) || theme.colors.PRIMARY} onClick={handleSubmit}>{t('globals.submit')}</Button>
      </Flex>
    </FlexSyled>
  );
};

export default MultipleChoiceType;
