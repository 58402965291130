const baseUrls = {
  1: 'https://www.youtube.com/embed/',
  2: '//player.vimeo.com/video/',
  3: '//w.soundcloud.com/player/?url=http%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F',
  4: '//embed.spotify.com/?uri=spotify%3Atrack%3A',
  5: '//fast.wistia.net/embed/iframe/',
  6: '//player.youku.com/embed/'
};

const isValidURL = str => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return pattern.test(str);
};

const parseYouTubeUrl = url => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : null;
};

const parseVimeoUrl = url => {
  if (url.split('/').length > 4) {
    const [id, hash] = url.replace('?share=copy', '').split('/').splice(-2);
    return `${id}?h=${hash}`;
  } else {
    const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?(.*)/;
    const match = url.replace('?share=copy', '').match(regExp);
    return match ? match[5] : null;
  }
};

const parseWistiaUrl = url => {
  const regExp = /^.*(wistia.com|home.wistia.com|wistia.net)\/(medias\/)?([A-z0-9]+)/;
  const match = url.match(regExp);
  return match ? match[3] : null;
};

const parseSpotifyUrl = url => {
  const regExp = /^.*(((spotify:)(track:)?)|(open.spotify.com)\/(track\/)?)([A-z0-9]+)/;
  const match = url.match(regExp);
  return match ? match[7] : null;
};

const parseSoundCloudUrl = url => {
  const regExp = /^.*(((spotify:)(track:)?)|(open.spotify.com)\/(track\/)?)([A-z0-9]+)/;
  const match = url.match(regExp);
  return match ? match[7] : null;
};

const parseYoukuUrl = url => {
  const regExp = /^.*(v.youku.com)\/(v_show\/id_)?([A-z0-9]+)==.html(.*)/;
  const match = url.match(regExp);
  return match ? match[3] : null;
};

const isYouTubeUrl = url => /(youtube|youtu\.be)/.test(url);
const isYoukuUrl = url => /(youku)/.test(url);
const isVimeoUrl = url => /(vimeo)/.test(url);
const isWistiaUrl = url => /(wistia)/.test(url);
const isSpotifyUrl = url => /(spotify|open\.spotify\.com)/.test(url);
const isSoundCloudUrl = url => /(soundcloud)/.test(url);

const getUrlFromProvider = provider => {
  if (!provider || typeof provider === 'string') {
    return provider;
  }
  if (provider.provider) {
    if (provider.provider === 8) {
      return null;
    } else if (provider.provider === 7) {
      return provider.id;
    } else {
      return `${window.document.location.protocol}${baseUrls[provider.provider]}${provider.id}`;
    }
  }
  return null;
};

const getEmbedUrl = url => {
  const provider = getProviderFromUrl(url);
  return provider ? getUrlFromProvider(provider) : null;
};

const getProviderFromUrl = url => {
  if (!isValidURL(url)) {
    return { provider: 8, id: url };
  }

  switch (true) {
    case isYouTubeUrl(url):
      return { provider: 1, id: parseYouTubeUrl(url) };
    case isYoukuUrl(url):
      return { provider: 6, id: parseYoukuUrl(url) };
    case isVimeoUrl(url):
      return { provider: 2, id: parseVimeoUrl(url) };
    case isWistiaUrl(url):
      return { provider: 5, id: parseWistiaUrl(url) };
    case isSpotifyUrl(url):
      return { provider: 4, id: parseSpotifyUrl(url) };
    case isSoundCloudUrl(url):
      return { provider: 3, id: parseSoundCloudUrl(url) };
    default:
      return { provider: 7, id: url };
  }
};

const Media = {
  baseUrls,
  isValidURL,
  parseYouTubeUrl,
  parseVimeoUrl,
  parseWistiaUrl,
  parseSpotifyUrl,
  parseSoundCloudUrl,
  parseYoukuUrl,
  isYouTubeUrl,
  isYoukuUrl,
  isVimeoUrl,
  isWistiaUrl,
  isSpotifyUrl,
  isSoundCloudUrl,
  getUrlFromProvider,
  getEmbedUrl,
  getProviderFromUrl
};

export default Media;
