import React, { Suspense, useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import PaymentCancelled from './features/webshop/PaymentCancelled';
import Checkout from './features/webshop/components/Checkout';
import Auth from './features/auth';
import Logout from './features/logout';
import WrappContainer from './features/global/WrappContainer';
import lazyLegacyRoot from './lazyLegacyRoot';
import Dashboard from './features/dashboard';
import Notes from './features/notes';
import Notifications from './features/notifications';
import CoursePreviewContainer from './features/course/CoursePreviewContainer';
import { selectLanguage, authStatus, selectAuthToken, loginGoogle, selectMyStore } from './features/auth/authSlice';
import { Loading, Section } from './ui';
import Invites from './features/invites';
import Users from './features/users';
import MediaLibrary from './features/media/MediaLibrary';
import Courses from './features/courses';
import CourseDetails from './features/course/CourseDetails';
import CourseUsers from './features/course/components/CourseUsers';
import CourseTasks from './features/course/components/CourseTasks';
import CourseQuiz from './features/course/components/CourseQuiz';
import CourseSettings from './features/course/components/Settings';
import Profile from './features/profile';
import Settings from './features/settings';
import ProfileInfo from './features/profile/components/ProfileInfo';
import ProfilePassword from './features/profile/components/ProfilePassword';
import ProfileNotifications from './features/profile/components/ProfileNotifications';
import Branding from './features/settings/components/Branding';
import Features from './features/settings/components/Features';
import Store from './features/settings/components/Store';
import Transactions from './features/transactions';
import Insights from './features/insights';
import Tracking from './features/settings/components/Tracking';
import TermsConditions from './features/settings/components/TermsConditions';
import Webinar from './features/settings/components/Webinar';
import { useModule } from './hooks/readFromUrls';
import GroupsList from './features/groups/components/GroupsList';
import UsersList from './features/users/components/UsersList';
import TeachersList from './features/users/components/TeachersList';
import Exports from './features/exports';
import CourseExports from './features/exports/components/CourseExports';
import UserExports from './features/exports/components/UserExports';
import GroupExports from './features/exports/components/GroupExports';
import AcceptInvitation from './features/invites/AcceptInvitation';
import RestorePassword from './features/auth/components/RestorePassword';
import ReclaimGift from './components/ReclaimGift';
import NotFound from './features/global/NotFound';
import CoursioError from './features/global/CoursioError';
import CoursioDown from './features/global/CoursioDown';
import ProfileGiftCode from './features/profile/components/ProfileGiftCode';
import Group from './features/groups/components/Group';
import { selectStoreOwner } from './features/webshop/globalSlice';
import { useTranslation } from 'react-i18next';
import ValidateDiploma from './features/diploma/ValidateDiploma';
import ProfileDiplomas from './features/profile/components/ProfileDiplomas';
import NavigateWithParams from './features/global/NavigateProps';
import Discounts from './features/discounts';
import NewReleases from './features/newReleases';
import ViewCourse from './features/viewCourse';
import CoursePage from './features/viewCourse/components/CoursePage';
import Faq from './features/Faq';
import SalesExport from './features/exports/components/SalesExport';
import PaymentConfirmed from './features/webshop/PaymentConfirmed';
import ResumeRegistration from './features/webshop/ResumeRegistration';
import Purchase from './features/webshop/Purchase';
import CoursioTermsAndConditions from './features/CoursioTermsAndConditions';
import WebshopContainer from './features/webshop/WebshopContainer';
import ViewShopCourse from './features/webshop/ShopViewer/ViewShopCourse';
import WebshopCourseContainer from './features/webshop/WebshopCourseContainer';
import EditShopFrontLayout from './features/webshop/ShopBuilder/EditShopFrontLayout';
import ShopCoursesList from './features/webshop/ShopBuilder/ShopCoursesList';
import EditShopCourse from './features/webshop/ShopBuilder/EditShopCourse';
import EditShopCourseLayout from './features/webshop/ShopBuilder/EditShopCourseLayout';

const LegacyMediaLibrary = lazyLegacyRoot(() => import('../../legacy/src/js/components/Route/MediaLibrary'));
const LegacyPage = lazyLegacyRoot(() => import('../../legacy/src/js/components/Route/Page'));

const RedirectIfAuth = ({ children }) => {
  const token = useSelector(selectAuthToken)
  const module = useModule();
  if (!!token && (!module || module === 'login')) {
    return <Navigate to={`/dashboard`} replace />;
  }
  return children;
}

const RequireAuth = ({ children }) => {
  const token = useSelector(selectAuthToken);
  const store = useStore();
  const module = useModule();

  const myStore = useSelector(selectMyStore);
  const visitingStore = useSelector(selectStoreOwner)

  if (!token) {
    return <Navigate to={`/${module === 'store' ? `${module}/${store}` : ''}`} replace />;
  }
  if (myStore && module === 'store_edit' && myStore !== visitingStore?.username) {
    return <Navigate to={`/store/${myStore}/edit/courses`} replace />
  }

  return children;
}

// export const LegacyComponent = ({ children }) => {
//   return (
//     <RequireAuth>
//       <Suspense fallback={<Section ><Loading /></Section>}>
//         {children}
//       </Suspense>
//     </RequireAuth>
//   )
// }

export const LegacyComponent = ({ children }) => {
  const roles = useSelector((state) => state.auth?.userInfo?.roles);

  // Redirect readers to dashboard
  if (roles?.includes('reader') && roles?.length === 1) {
    return <Navigate to={`/dashboard`} replace />;
  }

  return (
    <RequireAuth>
      <Suspense fallback={<Section><Loading /></Section>}>
        {children}
      </Suspense>
    </RequireAuth>
  );
};

export const loadGoogleScript = () => {
  const src = 'https://accounts.google.com/gsi/client';

  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  })
}

const App = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = useSelector(selectLanguage)

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [i18n, language])

  useEffect(() => {
    // client_id must be taken from env file when legacy is removed
    const client_id = '947317647447-3r9kcqdgu8nr6qs7ojc1u1nasu9rv96n.apps.googleusercontent.com';

    loadGoogleScript()
      .then(() => {
        /*global google*/
        google.accounts.id.initialize({
          client_id,
          callback: res => dispatch(loginGoogle(res.credential)),
        })
      })
      .catch(console.error)
  }, [dispatch])

  useEffect(() => {
    dispatch(authStatus());
  }, [dispatch])

  return (
    <BrowserRouter>
      <WrappContainer >
        <Routes>
          {/* ----STORE---- */}
          <Route exact path='/store/:name' element={<WebshopContainer></WebshopContainer> } />
          <Route exact path={'/store/:name/:course'} element={<WebshopCourseContainer><ViewShopCourse /></WebshopCourseContainer>} />
          <Route exact path={'/store/:name/package/:course'} element={<WebshopCourseContainer ><ViewShopCourse isPackage={true}/></WebshopCourseContainer> } />

          <Route path='/store/:name/edit' element={<RequireAuth><WebshopContainer edit /></RequireAuth>}>
            <Route path='courses' element={<RequireAuth><ShopCoursesList /></RequireAuth>} />
            <Route path='layout' element={<RequireAuth><EditShopFrontLayout /></RequireAuth>} />
            <Route path='discounts' element={<RequireAuth><Discounts /></RequireAuth>} />
          </Route>

          <Route exact path='/store/:name/:course/edit' element={<RequireAuth><EditShopCourse><EditShopCourseLayout /></EditShopCourse></RequireAuth>} />
          <Route exact path={'/store/:name/package/:course/edit'} element={<RequireAuth><EditShopCourse><EditShopCourseLayout isPackage /></EditShopCourse></RequireAuth>} />

          {/* Opens popup */}
          <Route exact path='/store/:name/:course/edit/settings' element={<RequireAuth><EditShopCourse /></RequireAuth>} />
          <Route exact path={'/store/:name/package/:course/edit/settings'} element={<RequireAuth><EditShopCourse isPackage /></RequireAuth>} />


          <Route exact path='/store/:name/checkout' element={<Checkout />} />
          <Route exact path='/store/:name/:courseSlug/purchase' element={<Purchase />} />
          <Route exact path={'/store/:name/package/:courseSlug/purchase'} element={<Purchase isPackage />} />

          {/* NEW COURSE VIEW  - working with a feature flag 'new-course-view' */}

          <Route path='/view/course/:courseId' element={<RequireAuth><ViewCourse /></RequireAuth>} >
            <Route path=':pageId' element={<RequireAuth><CoursePage /></RequireAuth>} />
            {/* <Route index element={} />
            <Route exact path=':sectionSlug/:pageSlug/:pageId' element={} /> */}
          </Route>

          {/* ----LEGACY ROUTES--- */}
          <Route path='/legacy/media-library' element={<LegacyComponent><LegacyMediaLibrary /></LegacyComponent>} />
          <Route path='/course/:ownerSlug/:courseSlug' element={<LegacyComponent><CoursePreviewContainer /></ LegacyComponent>} >
            <Route index element={<LegacyComponent><LegacyPage /></LegacyComponent >} />
            <Route exact path=':pageId' element={<LegacyComponent><LegacyPage /></LegacyComponent >} />
            <Route exact path=':sectionSlug/:pageSlug/:pageId' element={<LegacyComponent><LegacyPage /></LegacyComponent>} />
          </Route>

          {/* ------- */}
          <Route path='/notes' element={<RequireAuth><Notes /></RequireAuth>} />
          <Route path='/dashboard' element={<RequireAuth><Dashboard /></RequireAuth>} />
          <Route path='/communication' element={<RequireAuth><Notifications /></RequireAuth>} />
          <Route path='/invite' element={<RequireAuth><Invites /></RequireAuth>}>
            <Route path=':inviteId' element={<RequireAuth><Invites /></RequireAuth>} />
          </Route>
          <Route path='/users' element={<RequireAuth><Users /></RequireAuth>}>
            <Route path='all/:userId' element={<RequireAuth><UsersList modal /></RequireAuth>} />
            <Route path='all' element={<RequireAuth><UsersList /></RequireAuth>} />
            <Route path='teachers/:userId' element={<RequireAuth><TeachersList modal /></RequireAuth>} />
            <Route path='teachers' element={<RequireAuth><TeachersList /></RequireAuth>} />
            <Route path='groups' element={<RequireAuth><GroupsList /></RequireAuth>} />
            <Route path='groups/:groupId' element={<RequireAuth><Group /></RequireAuth>} />
            <Route path='groups/:groupId/:userId' element={<RequireAuth><Group modal /></RequireAuth>} />
          </Route>
          <Route path='/media-library' element={<RequireAuth><MediaLibrary /></RequireAuth>} />
          <Route path='/media-library/:folderId' element={<RequireAuth><MediaLibrary /></RequireAuth>} />
          <Route path='/media-library/:folderId/:fileId' element={<RequireAuth><MediaLibrary /></RequireAuth>} />
          <Route path='/transactions' element={<RequireAuth><Transactions /></RequireAuth>} />
          <Route path='/insights' element={<RequireAuth><Insights /></RequireAuth>} />
          <Route path='/exports' element={<RequireAuth><Exports /></RequireAuth>}>
            <Route path='course' element={<RequireAuth><CourseExports /></RequireAuth>} />
            <Route path='user' element={<RequireAuth><UserExports /></RequireAuth>} />
            <Route path='group' element={<RequireAuth><GroupExports /></RequireAuth>} />
            <Route path='sales' element={<RequireAuth><SalesExport /></RequireAuth>} />
          </Route>

          <Route path='/courses' element={<RequireAuth><Courses /></RequireAuth>} />
          <Route path='tasks/:courseId/:userId/:taskId' element={<NavigateWithParams to="/courses/:courseId/tasks/:taskId" />} />
          <Route path='/courses/:courseId' element={<RequireAuth><CourseDetails /></RequireAuth>}>
            <Route path='users/:userId' element={<RequireAuth><CourseUsers /></RequireAuth>} />
            <Route path='users/:userId/tasks' element={<RequireAuth><CourseUsers modal /></RequireAuth>} />
            <Route path='users' element={<RequireAuth><CourseUsers /></RequireAuth>} />
            <Route path='tasks' element={<RequireAuth><CourseTasks /></RequireAuth>} />
            <Route path='tasks/:taskId' element={<RequireAuth><CourseTasks /></RequireAuth>} />
            <Route path='tasks/:taskId/:userId' element={<RequireAuth><CourseTasks /></RequireAuth>} />
            <Route path='quiz' element={<RequireAuth><CourseQuiz /></RequireAuth>} />
            {/* <Route path='discussions' element={<RequireAuth><CourseDiscussions /></RequireAuth>} /> */}
            <Route path='settings' element={<RequireAuth><CourseSettings /></RequireAuth>} />
          </Route>
          <Route exact path='/profile' element={<RequireAuth><Profile /></RequireAuth>}>
            <Route path='info' element={<RequireAuth><ProfileInfo /></RequireAuth>} />
            <Route path='password' element={<RequireAuth><ProfilePassword /></RequireAuth>} />
            <Route path='diplomas' element={<RequireAuth><ProfileDiplomas /></RequireAuth>} />
            <Route path='notifications' element={<RequireAuth><ProfileNotifications /></RequireAuth>} />
            <Route path='gift' element={<RequireAuth><ProfileGiftCode /></RequireAuth>} />
          </Route>
          <Route exact path='/settings' element={<RequireAuth><Settings /></RequireAuth>}>
            <Route path='webinar' element={<RequireAuth><Webinar /></RequireAuth>} />
            <Route path='terms-conditions' element={<RequireAuth><TermsConditions /></RequireAuth>} />
            <Route path='tracking' element={<RequireAuth><Tracking /></RequireAuth>} />
            <Route path='store' element={<RequireAuth><Store /></RequireAuth>} />
            <Route path='features' element={<RequireAuth><Features /></RequireAuth>} />
            <Route path='branding' element={<RequireAuth><Branding /></RequireAuth>} />
          </Route>

          <Route exact path='/klarna/confirm/:hash' element={<PaymentConfirmed providerId={9} />} />
          <Route exact path='/stripe/confirm/:hash' element={<PaymentConfirmed providerId={6} />} />
          <Route exact path='/stripe/cancelled/:hash' element={<PaymentCancelled />} />
          <Route exact path='/new-releases' element={<NewReleases />} />
          <Route exact path='/faq' element={<Faq />} />
          <Route path="/resume-registration/:id" element={<ResumeRegistration />} />
          <Route path="/invitation/:hash" element={<AcceptInvitation />} />
          <Route path='/gift' element={<ReclaimGift />} />
          <Route path='/validate-diploma' element={<ValidateDiploma />} />
          {/* Logout should just be an action and not a page */}
          <Route exact path='/logout' element={<Logout />} />
          <Route exact path='/login' element={<RedirectIfAuth><Auth /></RedirectIfAuth>} />
          <Route exact path='/restore-password/:key' element={<RedirectIfAuth><RestorePassword /></RedirectIfAuth>} />
          <Route path='/terms-and-conditions' element={<CoursioTermsAndConditions />} />
          <Route exact path='/coursio-error' element={<CoursioError />} />
          <Route exact path='/down' element={<CoursioDown />} />
          <Route path='/' element={<RedirectIfAuth><Auth /></RedirectIfAuth>} />
          <Route path='*' element={<NotFound />} />

        </Routes>
      </WrappContainer>

    </BrowserRouter>
  );
}

export default App;
