export const s3HostName = 'coursio.s3-eu-west-1.amazonaws.com';
export const filesHostName = 'deathstar.s.coursio.com/files';

export const drawerWidth = 300;
export const coursePageNavigation = 62;

export const NOTIFICATIONS = {
  1: { t_key: 'addedToGroup', id: 1 }, // * [user:1] added you to [circle:1] in [course:65] // doesn't work
  2: { t_key: 'taskFeedback', id: 2 },// * gave feedback to [task:1] on [page:102] in [course:68]
  3: { t_key: 'postAdded', id: 3 }, // [user:1] posted [post:1] in [course:66]
  4: { t_key: 'taskAssessed', id: 4 }, //[user:%s] assessed [task:%s] in [course:%s]
  5: { t_key: 'youAreMentioned', id: 5 },// * [user:1] mentioned you on [page:102] in [course:68]
  6: { t_key: 'feedbackRequired', id: 6 }, // [user:%s] answered [task:%s] in [course:%s] [task_result:%s]
  7: { t_key: 'unreviewedTask', id: 7 },// * You have unreviewed tasks
  8: { t_key: 'threadNewPost', id: 8 }, // There are N new posts on [page:102] in [course:66]
  9: { t_key: 'courseNewPost', id: 9 },// * There are N new posts in [course:66]
  10: { t_key: 'courseSold', id: 10 }, // [user:1] has purchased N courses from you
  11: { t_key: 'coursePackageSold', id: 11 }, // [user:1] has purchased N course packages from you
  12: { t_key: 'addedToCourse', id: 12 }, // [user:1] added you to [course:65]
  // 13: { t_key: 'weMissYou', id: 13 },// * We miss you
  14: { t_key: 'dripContent', id: 14 },// * New drip content available
  15: { t_key: 'dataExport', id: 15 }, // Export complete
  16: { t_key: 'courseInvite', id: 16 }, // [user:%s] has sent you an [invitation:%s]
  17: { t_key: 'customMessages', id: 17 }, // [SHORT_LIFE] custom text - "it is for Mr Wolf when you want to feed a custom message"
};
// * = may not be in use

export const LANGUAGES = [
  { value: 'da_DK', label: 'Dansk', },
  { value: 'en_US', label: 'English' },
  { value: 'fr_FR', label: 'Français' },
  { value: 'de_DE', label: 'Deutsch' },
  { value: 'fi_FI', label: 'Suomalainen' },
  { value: 'nb_NO', label: 'Norsk' },
  { value: 'pl_PL', label: 'Polski' },
  { value: 'sv_SE', label: 'Svenska' },
  { value: 'nl_NL', label: 'Nederlandse' },
]

export const TOAST_SUCCESS = 'success'
export const TOAST_ERROR = 'error'
export const TOAST_WARNING = 'warning'
export const TOAST_INFO = 'info'

export const STATUS_SUCCEEDED = 'succeeded';
export const STATUS_FAILED = 'failed';
export const STATUS_LOADING = 'loading';

// how to use these: add the numbers so 184 = 128 + 32 + 16 + 8
export const API_STATUS = {
  STATUS_NEW: 1,
  STATUS_CART_OPENED: 2,  // obsolete
  STATUS_REGISTERED: 4,   // provider successfully registered our transaction
  STATUS_PAID: 8,        // the user completed the purchase procedure
  STATUS_GOOD: 16,        // we've got the money
  STATUS_ERROR: 32,
  STATUS_USER_CANCELED: 64,
  STATUS_REFUND: 128,
  STATUS_REAL: 248,
  STATUS_ALL: 2147483647, // 2^31 - 1
}
export const TASK_TYPE = {
  SINGLE: 1,
  MULTIPLE: 2,
  FORMULA: 3,
  OPEN: 4,
  NUMBER: 5, //not used
  SEQUENCE: 6,
  FILE: 7,
  SURVEY: 8,
  DUMMY: 9, //not used
}

export const MODULES_LIST = {
  dashboard: {
    section: 'personal',
    withoutSectionName: true,
    accessRights: ['reader', 'teacher', 'accountant', 'editor', 'owner', 'tester', 'assistant', 'dark-admin'],
    index: 1,
    label: (t) => t('globals.dashboard'),
    icon: 'Home',
    url: '/dashboard'
  },
  communication: {
    section: 'personal',
    withoutSectionName: true,
    accessRights: ['reader', 'teacher', 'accountant', 'editor', 'owner', 'tester', 'assistant', 'dark-admin'],
    index: 2,
    label: (t, unreadNotifications) => `${t('globals.notifications')} ${unreadNotifications ? '(' + unreadNotifications + ')' : ''}`,
    icon: 'Notifications', url: '/communication'
  },
  notes: {
    section: 'personal',
    withoutSectionName: true,
    accessRights: ['reader', 'teacher', 'accountant', 'editor', 'owner', 'tester', 'assistant', 'dark-admin'],
    index: 3,
    label: (t) => t('globals.notes'),
    icon: 'Notes',
    url: '/notes'
  },
  profile: {
    section: 'personal',
    withoutSectionName: true,
    accessRights: ['reader', 'teacher', 'accountant', 'editor', 'owner', 'tester', 'assistant', 'dark-admin'],
    index: 4,
    label: (t) => t('globals.profile'),
    icon: 'Profile',
    url: '/profile'
  },
  courses: {
    section: 'courseManagement',
    withoutSectionName: false,
    accessRights: ['teacher', 'owner', 'tester', 'assistant', 'dark-admin'],
    index: 5,
    label: (t) => t('globals.courses'),
    icon: 'Course',
    url: '/courses'
  },
  users: {
    section: 'courseManagement',
    withoutSectionName: false,
    accessRights: ['teacher', 'owner', 'tester', 'assistant', 'dark-admin'],
    index: 6,
    label: (t) => t('globals.users'),
    icon: 'Users',
    url: '/users'
  },
  invite: {
    section: 'courseManagement',
    withoutSectionName: false,
    accessRights: ['teacher', 'owner', 'tester', 'assistant', 'dark-admin'],
    index: 7,
    label: (t) => t('globals.invitations'),
    icon: 'Invite',
    url: '/invite'
  },
  store: {
    section: 'storeManagement',
    withoutSectionName: false,
    accessRights: ['owner', 'tester', 'assistant', 'dark-admin'],
    index: 8,
    label: (t) => `${t('globals.view')} ${t('globals.webshop')}`,
    icon: 'Storefront',
    url: (myStore) => `/store/${myStore}`
  },
  store_edit: {
    section: 'storeManagement',
    withoutSectionName: false,
    accessRights: ['owner', 'tester', 'assistant', 'dark-admin'],
    index: 9,
    label: (t) => `${t('globals.edit')} ${t('globals.webshop')} `,
    icon: 'EditStore',
    url: (myStore) => `/store/${myStore}/edit/courses`
  },
  transactions: {
    section: 'storeManagement',
    withoutSectionName: false,
    accessRights: ['accountant', 'owner', 'tester', 'assistant', 'dark-admin'],
    index: 10,
    label: (t) => t('globals.transactions'),
    icon: 'Receipt',
    url: `/transactions`
  },
  insights: {
    section: 'tools',
    withoutSectionName: false,
    accessRights: ['accountant', 'owner', 'tester', 'assistant', 'dark-admin'],
    index: 11,
    label: (t) => t('globals.statistics'),
    icon: 'Insights',
    url: `/insights`
  },
  exports: {
    section: 'tools',
    withoutSectionName: false,
    accessRights: ['teacher', 'accountant', 'owner', 'tester', 'assistant', 'dark-admin'],
    index: 12,
    label: (t) => t('globals.exports'),
    icon: 'Export',
    url: '/exports'
  },
  'media-library': {
    section: 'tools',
    withoutSectionName: false,
    accessRights: ['editor', 'owner', 'tester', 'assistant', 'dark-admin'],
    index: 13,
    label: (t) => t('globals.mediaLibrary'),
    icon: 'Media',
    url: `/media-library`
  },
  settings: {
    section: 'tools',
    withoutSectionName: false,
    accessRights: ['owner', 'tester', 'assistant', 'dark-admin'],
    index: 14,
    label: (t) => t('globals.settings'),
    icon: 'Settings',
    url: `/settings`
  },
}

export const baseMediaUrls = {
  1: 'https://www.youtube.com/watch?v=', // +
  2: '//player.vimeo.com/video/', // +
  3: '//w.soundcloud.com/player/?url=http%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F', //+
  4: '//embed.spotify.com/?uri=spotify%3Atrack%3A', // +
  5: '//fast.wistia.net/embed/iframe/', // -
  6: '//player.youku.com/embed/' // -
};

export const countryCodes = ['SE', 'NO', 'FI', 'DK', 'DE', 'GB', 'FR', 'PL'];

export const currenciesByCountry = { 'SE': 'SEK', 'NO': 'NOK', 'FI': 'EUR', 'DK': 'DKK', 'DE': 'EUR', 'GB': 'GBP', 'FR': 'EUR', 'PL': 'PLN' };

export const API_STATUS_ALL_TRANSACTIONS = API_STATUS.STATUS_REFUND + API_STATUS.STATUS_ERROR + API_STATUS.STATUS_GOOD + API_STATUS.STATUS_PAID

/* just to document the roles:
const GUEST = 0;                        // not logged in
const READER = 0b0000000000000001;       // good old reader
const TEACHER = 0b0000000000000010;       // can work with tasks and invite readers
const ACCOUNTANT = 0b0000000000000100;       // financial guy
const EDITOR = 0b0000000000001000;       // can build courses
const OWNER = 0b0001000000000000;       // owns Teh Bznss
const TESTER = 0b0010000000000000;       // has access to test features
const ASSISTANT = 0b0100000000000000;       // usual admin
const DARK_ADMIN = 0b1000000000000000;       // the great lord
const FULL_CLIENT = 0b0001000000001111;

const ALL_ACCESS = 0b1111000000001111;       // 61455 Admin with all access
const NOT_READER = 0b0001000000001110;
*/
